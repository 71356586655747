import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'footerLinksSort',
    pure: false
})
export class FooterLinksSortPipe implements PipeTransform {
    transform(items: any[]): any {
        if (!items || items.length < 2) {
            return items;
        }

        // Move "Contact Us" link from head to tail
        let first = items.shift()
        items.push(first)
        return items;
    }
}