import { NgModule, InjectionToken } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { AppComponent } from './app.component';

export let JQ_TOKEN = new InjectionToken<Object>('jQuery');
let jQuery = window['$'];
const appRoutes : Route[] = [
    { path: '', component: AppComponent, pathMatch: 'full' }
];
@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    providers:[
        {provide:JQ_TOKEN, useValue: jQuery}
    ],
    exports: [ RouterModule ]
})
export class AppRouterModule { }
