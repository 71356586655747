import { Component, OnInit } from '@angular/core';
import { AppService } from './app.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthConfig, OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { EntitlementService } from './shared/service/entitlement.service'

@Component({
    selector: 'pga-app',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

    title: string = "Growth Profitability Analytics (GPA)";
    authConfig: AuthConfig = {};
    errorMessage: string = '';
    loginStatus: string = 'loginStatus';

    constructor(
        private appService: AppService,
        private oauthService: OAuthService,
        private router: Router,
        private cookieService: CookieService,
        private entitlementService: EntitlementService
        ) {
            this.appService.getGlobalConfig().subscribe(
                _config => {
                  this.authConfig.issuer = _config.identityUrl                
                  this.authConfig.redirectUri =this.getRedirectUrl(_config.redirectUrl);
                  this.authConfig.clientId = _config.clientId
                  this.authConfig.scope = 'openid'
                  this.configureWithNewConfigApi()          
                },
                error => this.errorMessage = <any>error
            );
    }

    private configureWithNewConfigApi() {
        this.oauthService.configure(this.authConfig);
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();
        this.oauthService.loadDiscoveryDocumentAndTryLogin();
    }

    ngOnInit(): void {
        this.router.events.subscribe((routerEvent: Event) => {
            this.checkRouterEvent(routerEvent);
        });  
    }
    getRedirectUrl(redirectUrl: string): string {
      const regexUrl = new RegExp(redirectUrl);
      if (regexUrl.test(window.location.href)) {
        return regexUrl.exec(window.location.href)[0].replace('.iso.com','.verisk.com') + '/?domainMigrated=true';
      }
      return window.location.href.replace('.iso.com','.verisk.com') + '/?domainMigrated=true';
   
    }
    checkRouterEvent(routerEvent: Event): void {
        if (routerEvent instanceof NavigationStart) {
        }
    
        if (routerEvent instanceof NavigationEnd) {
    
            if(this.cookieService.get('ticket')){
              if(this.oauthService.getIdToken()){
                console.log("checkRouterEvent Condition 1")
                    this.entitlementService.setLoginStatus(true)
              }
              else if(!new RegExp('#id_token=.+').test(location.hash)){
                console.log("checkRouterEvent Condition 2")
                this.entitlementService.setLoginStatus(false)
                this.oauthService.initImplicitFlow();
              }
            }else{
              if(this.oauthService.getIdToken()){
                console.log("checkRouterEvent Condition 3")
                this.cookieService.deleteAll('/', '.iso.com');
                this.entitlementService.setLoginStatus(false)
                this.oauthService.logOut();
              }
              this.entitlementService.setLoginStatus(false)
            }
        }
    
        if (routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
        }
      }

}
