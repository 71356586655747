import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service'
import { HeaderService } from '../header/header.service';
import { IProfileLinks } from '../../../models/profilelink.model';
import { ISO_FOOTER_FILTER_ARGS } from '../../shared/constants/app.constants'

@Component({
    selector: "app-footer",
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {

  footerLinks = { corporateHome: '', termsAndConditions: '', privacyAndPolicy: '', contactUs: '' };
  errorMessage = '';
  lastHeight: number = 0;
  footerFilterArgs = ISO_FOOTER_FILTER_ARGS;
  userProfileLinks: IProfileLinks[];
  currentYear = new Date().getFullYear()

  constructor(
    private headerService: HeaderService,
    private appService: AppService,) {
      this.appService.getGlobalConfig().subscribe(
        _config => {
          this.headerService.getAllUserProfileLinks(_config.homePageApiUrl).subscribe(
            _userProfileLinks => {  
               //console.log('Data: ' + JSON.stringify(_userProfileLinks));
              this.userProfileLinks = _userProfileLinks;

              _userProfileLinks.forEach(item => {
                if (item.code === 'ISO_HOME') {
                  this.footerLinks.corporateHome = item.url;
                }
                if (item.code === 'TERMS') {
                  this.footerLinks.termsAndConditions = item.url;
                }
                if (item.code === 'POLICY') {
                  this.footerLinks.privacyAndPolicy = item.url;
                }
                if (item.code === 'CONTACT') {
                  this.footerLinks.contactUs = item.url;
                }                
              });

            },
            error => this.errorMessage = <any>error
          );  
        }
      )
  }

  ngOnInit(): void {
    setInterval(() => {
      let newHeight = document.getElementById('main').scrollHeight
      if(this.lastHeight !== newHeight){
        this.lastHeight = newHeight
        document.getElementById('footer').style.top = (newHeight + 94) + "px"
      }
    }, 500)
  }

}
