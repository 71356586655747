import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { HeaderService } from '../header/header.service';
import { AuthConfig, OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc'
import { AppService } from '../../app.service'
import { IUseCase } from '../../../models/usecase';

@Component({
    selector: "app-frontdoor",
    templateUrl: './frontdoor.component.html',
    styleUrls: ['./frontdoor.component.css']
})

export class FrontDoorComponent implements OnInit {

  useCases: IUseCase[];
  errorMessage = '';

  constructor(public appService: AppService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.appService.getUseCases().subscribe(
      _useCases => {
        this.useCases = _useCases;
      },
      error => this.errorMessage = <any>error
    );
  }

}
