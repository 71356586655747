declare var kWidget: any;
declare var $: any;
declare global {
  interface HTMLElement {
      sendNotification(msg: string): void;
  }
}
import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../app.service'
import { IProduct } from 'src/models/product';
import { Location } from '@angular/common';
import { PopStateEvent } from "@angular/common";
import { IUseCase } from 'src/models/usecase';
import { EntitlementService } from '../../shared/service/entitlement.service';

@Component({
    selector: "app-product",
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.css']
})

export class ProductComponent implements OnInit, AfterViewChecked {

  useCase: string = '';
  products: IProduct[];
  errorMessage = '';
  isAuthenticated: boolean = false;
  videoLinkInitializers: any = {};

  constructor(public appService: AppService,
    private location: Location,
    private route: ActivatedRoute,
    private entitlementService: EntitlementService) {
      this.location = location;
  }

  ngOnInit(): void {
    var hash = this.location.path(true).substring(1, this.location.path(true).length);
    if (hash !== '') {
      this.renderProducts(hash);
    }

    this.location.subscribe(
      ( event: PopStateEvent ): void => {
          this.renderProducts(event.url.substring(1, event.url.length));
      }
    );
  }

  ngAfterViewChecked(): void {
   this.initVideosFor();
  }

  renderProducts(hash: string): void {
    if (hash !== '') {
      this.appService.getUseCases().subscribe(
        _useCases => {
          this.useCase = _useCases.filter(us => us.slug === hash)[0].title;
        }
      );
      this.useCase = hash;

      this.appService.getProducts().subscribe(
        _products => {
          this.products = _products.filter(product => product.useCase.includes(hash));
          /* clear all initializers b/c we ar getting fresh products */
          this.videoLinkInitializers = {};
          this.entitlementService.getLoginStatus().subscribe(
            _status => {
              this.isAuthenticated = _status;
              if(_status){
                this.entitlementService.getUserEligibleGPAProducts().subscribe(
                  _gpaProducts => {
                    _gpaProducts.forEach(p => {
                      let index = this.products.findIndex((element) => {
                        return element.isoIdentifier === p.title;
                      })
                      if(index > -1){
                        this.products[index].isEligible = true;
                      }
                    })
                  }
                )
              }
            }
          )
        },
        error => this.errorMessage = <any>error
      );
    }
    else {
      //console.log('@@@5');
    }
  }

  stopEvent(event: any, isEligible: boolean): void {
    if(isEligible){
      event.stopPropagation();
    }
  }

  /**
   * Adobe Analytics Tracking
   * @param title 
   */
  demoRequestTrack(title: string): void{
    window.sessionStorage.setItem('demo_product', title);
  }

  learnMoreTrack(title: string): void{
    window.sessionStorage.setItem('product_learn_more', title);
  }

  productSelectTrack(title: string): void{
    window.sessionStorage.setItem('product_select', title);
  }

   /**
   * This method is repeatedly called. It executes any functions
   * stored in this.videoLinkInitializers
   */
  initVideosFor(): void {
    for(const slug in this.videoLinkInitializers) {
      if (!this.videoLinkInitializers[slug] === false) {
        this.videoLinkInitializers[slug]();
      }
    }
  }

  /**
   * This method uses jQuery for DOM manipulation. It creates
   * a function that initializes Kaltura PLayer for each video 
   * and stores that function in this.videoLinkInitializers
   * @param product: IProduct
   */
  addVideoLinkInitializer(product: IProduct): void {
    const slug = product.slug;
    if (!(this.videoLinkInitializers[slug] === false)) {
      this.videoLinkInitializers[slug] = () => {
        kWidget.embed({
          'targetId': 'kalPlayer-' + slug,
          'wid': '_2090141',
          'uiconf_id': 39435801,
          'entry_id': product.video.entryId,
          'flashvars': {
              'thumbnailUrl': product.video.thumbnailUrl
          }
        });
        $('#modal-' + slug).on('hidden.bs.modal', function () {
          const player = document.getElementById('kalPlayer-' + slug);
          player.sendNotification('doPause');
        });
        this.videoLinkInitializers[slug] = false;
      };
    }
  }

}
