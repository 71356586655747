import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map, filter } from 'rxjs/operators';
import { IUseCase } from '../models/usecase';
import { IProduct } from '../models/product';
import { IConfig } from '../models/config';

@Injectable()
export class AppService {

    private useCaseModel = '../assets/jsons/usecases.json';
    private productModel = '../assets/jsons/products.json';
    private globalConfig = '../assets/jsons/config.json';

    constructor(private http: HttpClient) {        
    }
        
    getUseCases(): Observable<IUseCase[]> {
        return this.http.get<IUseCase[]>(this.useCaseModel).pipe(
          tap(data => console.log('JSON Data: ' + JSON.stringify(data))),
          //tap(data => console.log('JSON Data: getUseCases')),
          catchError(this.handleError)
        );
    }

    getUseCase(slug: string): Observable<IUseCase> {
        return this.http.get<IUseCase>(this.useCaseModel)
        .pipe(
            filter(response => response.slug == slug)
        );
    }

    getProducts(): Observable<IProduct[]> {
        console.log('getProducts Called');
        return this.http.get<IProduct[]>(this.productModel).pipe(
          //tap(data => console.log('JSON Data: ' + JSON.stringify(data))),
          tap(data => console.log('JSON Data: getProducts::' + data.length)),
          catchError(this.handleError)
        );
    }

    getGlobalConfig(): Observable<IConfig> {
        return this.http.get<IConfig>(this.globalConfig).pipe(
          //tap(data => console.log('JSON Data: ' + JSON.stringify(data))),
          tap(data => console.log('JSON Data: getGlobalConfig')),
          catchError(this.handleError)
        );
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
}
