import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { AppRouterModule } from './app-router.module';
import { CommonModule } from '@angular/common';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FrontDoorComponent } from './components/frontdoor/frontdoor.component';
import { ProductComponent } from './components/product/product.component';
import { RequestDemoComponent } from './components/request-demo/request-demo.component';

import { UserProfileFilterPipe } from './shared/pipes/userprofilefilter.pipe';
import { FooterLinksSortPipe } from './shared/pipes/footerlinks.pipe';
import { CookieService } from 'ngx-cookie-service';
import { AppService} from '../app/app.service';
import { HeaderService } from './components/header/header.service';


@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpModule,
    AppRouterModule,
    CommonModule,
    OAuthModule.forRoot()
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    FrontDoorComponent,
    ProductComponent,
    RequestDemoComponent,
    UserProfileFilterPipe,
    FooterLinksSortPipe
  ],
  providers: [
    HeaderService,
    CookieService,
    AppService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
