import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../app.service'
import { Location } from '@angular/common';
import { IUseCase } from '../../../models/usecase';

@Component({
    selector: "request-demo",
    templateUrl: './request-demo.component.html',
    styleUrls: ['./request-demo.component.css']
})

export class RequestDemoComponent implements OnInit {

  constructor(public appService: AppService,
    private location: Location,
    private route: ActivatedRoute) {

      this.location = location;
  }

  ngOnInit(): void {

  }

}
